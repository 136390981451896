* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    /* font-family: 'PT Sans', sans-serif; */
    font-family: "Noto Serif HK";
  }
  
  .home,
  .services,
  .products,
  .sign-up {
    display: flex;
    height: 100vh;
    align-items: center;
    justify-content: center;
    font-size: 3rem;
  }
  
  .services {
    /* background-image: url(''); */
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    color: #fff;
    font-size: 100px;
  }
  
  .products {
    /* background-image: url(''); */
    background-position: center;
    background-size: fill;
    background-repeat: no-repeat;
    color: #fff;
    font-size: 100px;
  }
  
  .sign-up {
    /* background-image: url(''); */
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    color: #fff;
    font-size: 100px;
  }
  .locate {
    /* background-image: url(''); */
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    color: #fff;
    object-fit: cover;
  }
  