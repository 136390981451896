body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
.product__container{
    background-color: #242424;
    display: flex;
    max-width: 100%;
    width: 100%;
    margin: 0 auto;
}
.product__list{
    position: relative;
    left: 2.5%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    max-width: 100%;
    width: 95%;
    
}
@media screen and (max-width: 1024px){
    
}
@media screen and (max-width: 960px){}
@media screen and (max-width: 550px){}
@media screen and (max-width: 450px){}