.footer-container {
    background-color: #242424;
    padding: 4rem 0 2rem 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.footer-subscription {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-bottom: 24px;
    padding: 24px;
    color: #fff;
    width: auto;
}

.footer-subscription>p {
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

.footer-subscription-heading {
    margin-bottom: 24px;
    font-size: 24px;
}

.footer-subscription-text {
    margin-bottom: 24px;
    font-size: 20px;
}

/* .footer-input {
    padding: 8px 20px;
    border-radius: 2px;
    margin-right: 10px;
    outline: none;
    border: none;
    font-size: 18px;
    margin-bottom: 16px;
    border: 1px solid #fff;
} */

.footer-links {
    width: 100%;
    max-width: 1000px;
    display: flex;
    justify-content: center;
}

.footer-link-wrapper {
    display: flex;
}

.footer-link-items {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 16px;
    text-align: left;
    width: 160px;
    box-sizing: border-box;
}

.footer-link-item h2 {
    margin-bottom: 16px;
}

.footer-link-items>h2 {
    color: #fff;
    margin-bottom: 16px;
}

.footer-link-items a {
    color: #fff;
    text-decoration: none;
    margin-bottom: 8px;
}

.footer-link-items a:hover {
    color: #e9e9e9;
    transition: 0.3s ease-out;
}

/* .footer-email-form h2 {
    margin-bottom: 32px;
} */

/* .footer-input::placeholder {
    color: #b1b1b1;
} */

/* social icon */
.social-icon-link {
    color: #fff;
    font-size: 24px;
}

.social-media {
    max-width: 1000px;
    width: 100%;
}

.social-media-wrap {
    display: flex;
    justify-content:space-between;
    align-items: center;
    width: 90%;
    max-width: 1000px;
    margin: 20px auto 0 auto;
}

.social-icons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 200px;
}

.social-logo {
    color: #fff;
    justify-self: start;
    /* margin-left: 20px; */
    cursor: pointer;
    text-decoration: none;
    font-size: 2rem;
    display: flex;
    align-self: center;
    margin-bottom: 16px;
    margin-right: 68px;
}

.social-logo:hover,
.social-icon-link:hover {
    transform: scale(1.1);
    transition: 0.3s ease-out;
}

.website-rights {
    color: #fff;
    margin-bottom: 16px;
    width: 240px;
}

/* form */
/* form {
    display: flex;
    flex-flow: column;
    align-items:flex-start;
    justify-items: center;
    max-width: 1500px;
    width: 100%;
    font-size: 16px;
    margin: 50px auto;

}

input {
    width: 100%;
    height: 35px;
    padding: 7px;
    outline: none;
    border-radius: 5px;
    border: 1px solid rgb(220, 220, 220);

    &:focus {
        border: 2px solid rgba(0, 206, 158, 1);
    }
}
textarea{
    max-width: 100%;
    min-width: 100%;
    width: 100%;
    max-height: 100px;
    min-height: 100px;
    padding: 7px;
    outline: none;
    border-radius: 5px;
    border: 1px solid #fff;
    &:focus{
        border: 2px solid rgba(0,206,158,1);
    }
}
label{
    margin-top:10px;
    margin-bottom: 10px;
    font-size: 1.2rem;
    color: #fff;
}
input[type='submit']{
    margin-top: 40px;
    background-color: none;
    color: #242424;
    font-size: 1.2rem;
    padding: 4px 20px;
    border: 1px solid #fff;
    
}
input[type='submit']:hover{
    margin-top: 35px;
    background-color: transparent;
    color: #fff;
    border: 2px solid ;
    transition: all 0.5s ease-out;
} */

@media screen and (max-width:820px) {
    .footer-link {
        padding-top: 2rem;
    }

    .footer-input {
        width: 100%;
    }

    .btn {
        width: 100%;
    }

    .footer-link-wrapper {
        flex-direction: column;
    }

    .social-media-wrap {
        flex-direction: column;
    }
}
@media screen and (max-width: 450px) {
    
}