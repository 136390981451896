video {
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: -1;
}

.section-container {
    /* background: url('/public/images/solarsystem.jpg') center center/cover no-repeat; */
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
    object-fit: cover;
    background: center center /cover no-repeat;
}

.section-container .video {
    object-fit: cover;
    overflow: hidden;
    width: 100vw;
    height: 100vh;
}

h1[class=title] {
    color: #fff;
    font-size: 7rem;
    margin-top: -50px;
    margin-bottom: 60px;
    margin-left: 20px;
    margin-right: 20px;
    font-family: "Noto Serif HK";
    text-align: center;
}

h1[class=slogan] {
    color: #fff;
    font-size: 5rem;
    margin-top: -80px;
    margin-bottom: 48px;
    font-family: "Noto Serif HK";
}

h3[class=subtitle] {
    color: #fff;
    font-size: 4rem;
    margin-top: -30px;
    /* font-family: sans-serif; */
    font-family: "Noto Serif HK";
    text-align: center;
}

.section-container>p {
    margin-top: 8px;
    color: #fff;
    font-size: 2rem;
    font-family: sans-serif;
}

.hero-btns {
    margin-top: 32px;
}

.hero-btns .btn {
    margin: 6px;
}

.fa-play-circle {
    margin-left: 4px;
}

@media screen and (max-width:960px) {
    
    h1[class=title] {
        font-size: 70px;
        margin-top: -150px;
        margin-left: 20px;
        margin-right: 20px;
    }

    h1[class=slogan] {
        font-size: 3rem;
        margin-top: -90px;
        margin-bottom: 48px;
    }

}

@media screen and (max-width:768px) {
    h1[class=title] {
        font-size: 50px;
        margin-top: -100px;
        margin-left: 10px;
        margin-right: 10px;
    }

    h1[class=slogan] {
        font-size: 3rem;
        margin-top: -50px;
        margin-bottom: 48px;
    }

    h3[class=subtitle] {
        font-size: 3rem;
        margin-left: 10px;
        margin-right: 10px;
    }

    p[class=subslogan] {
        font-size: 30px;
    }

    .btn-mobile {
        display: block;
        text-decoration: none;
    }

    .btn {
        width: 100%;
    }
}

@media screen and (max-width:525px) {
    .section-container {
        height: 80vh;
    }

    h1[class=title] {
        font-size: 2.0rem;
        margin-top: -300px;
        padding: 40px;
    }

    h1[class=slogan] {
        color: #fff;
        font-size: 1.5rem;
        margin-top: -90px;
        margin-bottom: 48px;
    }

    h3[class=subtitle] {
        font-size: 2.0rem;
        margin-top: -40px;
    }

    p[class=subslogan] {
        /* display: none; */
        font-size: 1.5rem;
        margin-top: 10px;
    }
}

@media screen and (max-width:430px) {

    /* .section-container {
        background: url('/public/images/solarsystem.jpg') center center/cover no-repeat;
    }

    .section-container .video {
        display: none;
    } */

    .section-container {
        height: 80vh;
    }

    h1[class=title] {
        font-size: 1.8rem;
        margin-top: -300px;
    }

    h1[class=slogan] {
        color: #fff;
        font-size: 1.5rem;
        margin-top: -90px;
        margin-bottom: 48px;
    }

    h3[class=subtitle] {
        font-size: 1.5rem;
        margin-top: -40px;
    }

    p[class=subslogan] {
        /* display: none; */
        font-size: 1.0rem;
        margin-top: 10px;
    }

    .btn-mobile {
        display: block;
        text-decoration: none;
    }

    .btn {
        width: 100%;
    }
}