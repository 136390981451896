body{
    background-color: #242424;
}
form {
    display: flex;
    flex-flow: column;
    align-items:flex-start;
    justify-items: center;
    max-width: auto;
    width: 30%;
    font-size: 16px;
    margin: 50px auto;
}

input {
    width: 100%;
    height: 35px;
    padding: 7px;
    outline: none;
    border-radius: 5px;
    border: 1px solid #dcdcdc;

    &:focus {
        border: 2px solid #00ce9e;
    }
}
textarea{
    max-width: 100%;
    min-width: 100%;
    width: 100%;
    max-height: 100px;
    min-height: 100px;
    padding: 7px;
    outline: none;
    border-radius: 5px;
    border: 1px solid rgb(220,220,220);
    &:focus{
        border: 2px solid rgba(0,206,158,1);
    }
}
label{
    margin-top:20px;
    margin-bottom: 10px;
    font-size: 1.2rem;
    color: #fff;
}
input[type='submit']{
    margin-top: 40px;
    background-color: none;
    color: #242424;
    font-size: 1.2rem;
    font-weight:900;
    padding: 4px 20px;
    border: 1px solid #fff;
    
}
input[type='submit']:hover{
    margin-top: 40px;
    background-color: transparent;
    color: #fff;
    border: 1px solid;
    border-inline: 1px solid #9d9d9d;
    transform: scale(1.01);
    transition: all 0.3s ease-out;
}
@media screen and (max-width: 960px) {
    form {
        max-width: 960px;
        width: 70%;
        font-size: 16px;
        margin: 50px auto;
    }
}
@media screen and (max-width: 550px) {
    form {
        max-width: 550px;
        width: 70%;
        font-size: 16px;
        margin: 50px auto;
    }
}
@media screen and (max-width: 450px) {
    form {
        max-width: 450px;
        width: 70%;
        font-size: 16px;
        margin: 50px auto;
    }    
}