body{
    margin: 0;
    padding: 0;
    color: #fff;
}
.services_container{
    display: flex;
    flex-wrap: wrap;
    margin: 40px;
    max-width:fit-content;
    width: 100%;
    align-self: center;
}
.content{
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    margin: 0;
    padding: 0;
}
.content>ol{
    margin-left: 30px;
}
.contact_info{
    color: #6eeae0;
    cursor: pointer;
    font-size: 1.5rem;
}
.content>ul{
    margin-left: 42px;
}
@media screen and (max-width:820px){
    .services_container{margin: 30px;}
}