.locate_container {
    display: flex;
    flex-direction: row;
    max-width:max-content;
    width: 100%;
    padding: 40px;
    margin:0px;
    font-size: 2rem;
}
.info{
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin:0px 80px;
}
.info>h1,h2 {
    text-align: left;
    width:fit-content;
    color: #fff;
}
.map{
    flex-direction: column;
    margin: 50px;
    align-self: flex-start;
    width:400; 
    height:300;
    
}
@media screen and (max-width:960px){
    .locate_container {
        display: flex;
        flex-direction: column;
        max-width:100%;
        width: 70%;
        padding: 40px;
        margin:70px;
    }
    .info{
        align-items: center;
        width: 100%;
        margin:0px 0px;
    }
    .map{
        flex-direction: column;
        margin: 70px;
        align-self:flex-start;
    }
}
@media screen and (max-width:550px){
    .locate_container {
        display: flex;
        flex-direction: column;
        max-width:100%;
        width: 70%;
        padding: 40px;
        margin:0px;
        font-size: 1.5rem;
    }
    .map{
        flex-direction: column;
        margin: 70px;
        align-self:flex-start;
    }
    
}
@media screen and (max-width:430px){
    .locate_container {
        display: flex;
        flex-direction: column;
        max-width:100%;
        width: 100%;
        padding: 10px;
        margin:30px;
        font-size: 1.2rem;
    }
    .info{
        align-items: center;
        width: 90%;
        margin:0px 0px;
    }
    .map{
        flex-direction: column;
        margin: 0px;
        align-self:flex-start;
    }
    
}