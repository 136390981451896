/* body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
} */
.card_col{
    display: flex;
    flex-direction: column;
}

.products_card{
    margin: 15px;
    padding: 10px;
    /* border-radius: 5px; */
    box-shadow: 0 0 3px #ccc;
    background-color: #f7f7f7;
    border: none;
    outline: none;
    height: auto;
    display:inline-block;
}

.card_thumb{
    max-width: 100%;
    width: 235px;
    height: 200px;
    object-fit: cover;
    display: block;
    cursor: pointer;
    /* border-radius: 5px 5px 0px 0px; */
    /* box-shadow: 0 0 8px #ccc; */
}

.card_body{
    padding: 10px;
    background-color: #333333;
    /* border-radius: 0px 0px 5px 5px; */
    height: auto;
    width: 235px;
}

.card_title {
    font-size: 1.0rem;
    color: #fff;
    margin-bottom: 4px;
}
.card_price {
    font-size: 1.0rem;
    color: #fff;
    margin-bottom: 4px;
}
.card_button{
    font-size: 1.2rem;
    padding: 5px;
    border: 1px solid #fff;
    border-radius: 5px;
}

@media screen and (max-width: 1024px){
    .card_thumb{
        max-width: 100%;
        width: 200px;
        height: auto;
        object-fit: cover;
    }
    .card_body{
        padding: 10px;
        height: auto;
        width: 200px;
    }
    .products_card{
        margin: 9px;
        padding: 10px;
    }    
}
@media screen and (max-width: 960px){
    .card_thumb{
        width: 380px;
        height: auto;
        object-fit: cover;
    }
    .card_body{
        padding: 10px;
        height: auto;
        width: 380px;
    }
    .products_card{
        margin: 10px;
        padding: 10px;
    }
    .card_title {
        font-size: 1.5rem;
        color: #fff;
    }
    .card_price {
        font-size: 1.5rem;
        color: #fff;
    }
    .card_button{
        font-size: 1.5rem;
        padding: 5px;
        border: 1px solid #fff;
        border-radius: 5px;
    }
    
}
@media screen and (max-width:860px) {
    .products_card{
        margin: 10px;
    }
    .card_thumb{
        width: 200px;
        height: auto;
        object-fit: cover;
    }
    .card_body{
        padding: 10px;
        height: auto;
        width: 200px;
    }
    .card_title {
        font-size: 1.0rem;
        color: #fff;
    }
    .card_price {
        font-size: 1.0rem;
        color: #fff;
    }
    .card_button{
        font-size: 1.0rem;
        padding: 5px;
        border: 1px solid #fff;
        border-radius: 5px;
    }
        
}
@media screen and (max-width: 780px){
    .card_thumb{
        width: 300px;
        height: auto;
        object-fit: cover;
    }
    .card_body{
        padding: 10px;
        height: auto;
        width: 300px;
    }
    .products_card{
        margin: 20px;
        padding: 10px;
    }
}
@media screen and (max-width: 550px){
    .card_thumb{
        width: 390px;
        height: auto;
        object-fit: cover;
    }
    .card_body{
        padding: 10px;
        height: auto;
        width: 390px;
    }
    .products_card{
        margin: 10px;
        margin-left: 40px;
        padding: 10px;
    }
}
@media screen and (max-width: 450px){
    .card_thumb{
        width: 330px;
        height: auto;
        object-fit: cover;
    }
    .card_body{
        padding: 10px;
        height: auto;
        width: 330px;
    }
    .products_card{
        margin: 20px;
        padding: 10px;
    }
}
@media screen and (max-width: 400px){
    .card_thumb{
        width: 300px;
        height: auto;
        object-fit: cover;
    }
    .card_body{
        padding: 10px;
        height: auto;
        width: 300px;
    }
    .products_card{
        margin: 10px;
        padding: 10px;
    }
}