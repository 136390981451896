/* .line_container{
    max-width: fit-content;
    width: 100%;
    margin: 0;
    padding: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
} */
/* .line_container{
    display: flex;
    justify-content: center;
    align-items: center;
} */
.line_container{
    display: grid;
    place-items: center;
    height: 300px;
}
.line_img{
    width: 200px;
    height: 200px;
}